
import { Vue, Component } from "vue-property-decorator";
import { mapMutations, mapGetters } from "vuex";
import { ResponseDataStringArray as rdsa } from "@/interfaces";
import * as types from "@/store/types";
@Component({
    components: {
        FilterFormPanelBase: () =>
            import("@/components/layout/FilterFormPanelBase.vue"),
        CardWidget: () => import("@/components/layout/CardWidget.vue"),
        CalendarInput: () => import("@/components/layout/CalendarInput.vue")
    },
    methods: {
        ...mapMutations({
            setProgressBar: types.MUTATE_APP_PROGRESSBAR,
            setInfoMessage: types.MUTATE_APP_INFO_MESSAGE
        })
    },
    computed: {
        ...mapGetters({
            userIdentifier: types.USER_IDENTIFIER
        })
    }
})
export default class Prices extends Vue {
    setProgressBar!: (state: boolean) => void;
    setInfoMessage!: (state: { shown: boolean; text: string | null }) => void;
    showError(error: string) {
        this.setInfoMessage({ shown: true, text: error });
    }
    role = Vue.prototype.$user.get().role;
    userIdentifier?: number;
    companies: string[] = [];
    company = "";
    clients: string[] = [];
    client = "";
    installations: string[] = [];
    install = "";
    services: string[] = [];
    Fenergy = false;
    Fagua = false;
    Fgas = false;
    Fnitrogeno = false;
    p1 = "0";
    p2 = "0";
    p3 = "0";
    p4 = "0";
    p5 = "0";
    p6 = "0";
    pc1 = "0";
    pc2 = "0";
    pc3 = "0";
    pc4 = "0";
    pc5 = "0";
    pc6 = "0";
    ElecContrato = "2022-01-01";
    GasContrato = "2022-01-01";
    AguaContrato = "2022-01-01";
    NtrgContrato = "2022-01-01";
    agua = "0";
    gas = "0";
    nitrogeno = "0";
    aguaC = "0";
    gasC = "0";
    nitrogenoC = "0";
    rules = {
        required: value => !!value || "Requerido",
        counter: value => value.length <= 6 || "Maximo 6 dígitos",
        days: value => !!Number(value) || "No es un número"
    };
    Nprecio = 0;
    dialog = false;
    dialogDelete = false;
    electricHead = [
        { text: "N°", value: "Nprecio" },
        {
            text: "Fecha Contrato",
            align: "start",
            value: "Fcontrato"
        },
        {
            text: "Fecha Precio",
            align: "start",
            value: "Fprecio"
        },
        { text: "€/kWh P1", value: "Tp1" },
        { text: "€/kWh P2", value: "Tp2" },
        { text: "€/kWh P3", value: "Tp3" },
        { text: "€/kWh P4", value: "Tp4" },
        { text: "€/kWh P5", value: "Tp5" },
        { text: "€/kWh P6", value: "Tp6" },
        { text: "kW P1", value: "Pp1" },
        { text: "kW P2", value: "Pp2" },
        { text: "kW P3", value: "Pp3" },
        { text: "kW P4", value: "Pp4" },
        { text: "kW P5", value: "Pp5" },
        { text: "kW P6", value: "Pp6" },
        { text: "Actions", value: "actions", sortable: false }
    ];

    header = [
        { text: "N°", value: "Nprecio" },
        {
            text: "Fecha Contrato",
            align: "start",
            value: "Fcontrato"
        },
        {
            text: "Fecha Precio",
            align: "start",
            value: "Fprecio"
        },
        { text: "€/m³", value: "Tp1" },
        { text: "m³", value: "Pp1" },
        { text: "Actions", value: "actions", sortable: false }
    ];

    desserts: any = [];
    dessgas: any = [];
    dessagua: any = [];
    dessnitrogeno: any = [];
    editedIndex = -1;
    editedItem = {
        Fcontrato: "",
        Fprecio: "",
        Nprecio: "0",
        Tp1: "0",
        Pp1: "0",
        Tp2: "0",
        Pp2: "0",
        Tp3: "0",
        Pp3: "0",
        Tp4: "0",
        Pp4: "0",
        Tp5: "0",
        Pp5: "0",
        Tp6: "0",
        Pp6: "0"
    };
    defaultItem = {
        Fcontrato: "",
        Fprecio: "",
        Nprecio: "0",
        Tp1: "0",
        Pp1: "0",
        Tp2: "0",
        Pp2: "0",
        Tp3: "0",
        Pp3: "0",
        Tp4: "0",
        Pp4: "0",
        Tp5: "0",
        Pp5: "0",
        Tp6: "0",
        Pp6: "0"
    };

    formTitle() {
        return this.editedIndex === -1 ? "New Item" : "Editar Precio";
    }

    watch = {
        dialog(val) {
            val || this.close();
        },
        dialogDelete(val) {
            val || this.closeDelete();
        }
    };

    editItem(item) {
        this.editedIndex = this.desserts.indexOf(item);
        this.editedItem = Object.assign({}, item);
        this.dialog = true;
    }

    deleteItem(item) {
        this.editedIndex = this.desserts.indexOf(item);
        this.editedItem = Object.assign({}, item);
        this.dialogDelete = true;
        this.Nprecio = this.desserts[this.editedIndex]["Nprecio"];
    }

    deleteItemConfirm() {
        this.desserts.splice(this.editedIndex, 1);
        this.deletePrice();
        this.closeDelete();
    }

    close() {
        this.dialog = false;
        this.$nextTick(() => {
            this.editedItem = Object.assign({}, this.defaultItem);
            this.editedIndex = -1;
        });
    }

    closeDelete() {
        this.dialogDelete = false;
        this.$nextTick(() => {
            this.editedItem = Object.assign({}, this.defaultItem);
            this.editedIndex = -1;
        });
    }

    save() {
        if (this.editedIndex > -1) {
            Object.assign(this.desserts[this.editedIndex], this.editedItem);
        } else {
            this.desserts.push(this.editedItem);
        }
        this.Nprecio = this.desserts[this.editedIndex]["Nprecio"];
        this.upgradePrice();
        this.close();
    }

    mounted() {
        this.fetchUser();
    }

    async fetchUser() {
        if (this.role == "client") {
            this.setProgressBar(true);
            try {
                const userClientResponse = await this.$api.SQLuserClient<rdsa>(
                    this.userIdentifier
                );
                if (userClientResponse.ok) {
                    this.company = userClientResponse.data[0];
                    this.client = userClientResponse.data[1];
                    this.fetchInstallation();
                }
            } catch (error) {
                if (error instanceof Error) {
                    this.showError(error.message);
                }
            } finally {
                this.setProgressBar(false);
            }
        } else if (this.role == "admin") {
            this.fetchCompany();
        } else {
            console.log("Role undefined");
        }
    }

    async fetchCompany() {
        this.setProgressBar(true);
        try {
            const companyResponse = await this.$api.SQLcompany<rdsa>();
            if (companyResponse.ok) {
                this.companies = companyResponse.data;
                this.company = this.companies[0];
                this.fetchClient();
            }
        } catch (error) {
            if (error instanceof Error) {
                this.showError(error.message);
            }
        } finally {
            this.setProgressBar(false);
        }
    }

    async fetchClient() {
        this.setProgressBar(true);
        try {
            const clientResponse = await this.$api.SQLclient<rdsa>(
                this.company.split(" - ")[0]
            );
            if (clientResponse.ok) {
                this.clients = clientResponse.data;
                this.client = this.clients[0];
                this.fetchInstallation();
            }
        } catch (error) {
            if (error instanceof Error) {
                this.showError(error.message);
            }
        } finally {
            this.setProgressBar(false);
        }
    }

    async fetchInstallation() {
        this.setProgressBar(true);
        try {
            const installResponse = await this.$api.SQLinstallation<rdsa>(
                this.client.split(" - ")[0]
            );
            if (installResponse.ok) {
                this.installations = installResponse.data;
                this.install = this.installations[0];
                this.fetchPrices();
            }
        } catch (error) {
            if (error instanceof Error) {
                this.showError(error.message);
            }
        } finally {
            this.setProgressBar(false);
        }
    }

    async fetchPrices() {
        this.setProgressBar(true);
        try {
            const serviceResponse = await this.$api.getServicesInstall<rdsa>(
                this.install.split(" - ")[0]
            );
            const pricesResponse = await this.$api.getPrices<rdsa>(
                this.install.split(" - ")[0]
            );
            if (serviceResponse.ok) {
                this.services = serviceResponse.data;
            }
            if (pricesResponse.ok) {
                try {
                    this.desserts = pricesResponse.data["electrico"];
                    this.p1 = String(
                        pricesResponse.data["electrico"][0]["Tp1"]
                    );
                    this.p2 = String(
                        pricesResponse.data["electrico"][0]["Tp2"]
                    );
                    this.p3 = String(
                        pricesResponse.data["electrico"][0]["Tp3"]
                    );
                    this.p4 = String(
                        pricesResponse.data["electrico"][0]["Tp4"]
                    );
                    this.p5 = String(
                        pricesResponse.data["electrico"][0]["Tp5"]
                    );
                    this.p6 = String(
                        pricesResponse.data["electrico"][0]["Tp6"]
                    );
                    this.pc1 = String(
                        pricesResponse.data["electrico"][0]["Pp1"]
                    );
                    this.pc2 = String(
                        pricesResponse.data["electrico"][0]["Pp2"]
                    );
                    this.pc3 = String(
                        pricesResponse.data["electrico"][0]["Pp3"]
                    );
                    this.pc4 = String(
                        pricesResponse.data["electrico"][0]["Pp4"]
                    );
                    this.pc5 = String(
                        pricesResponse.data["electrico"][0]["Pp5"]
                    );
                    this.pc6 = String(
                        pricesResponse.data["electrico"][0]["Pp6"]
                    );
                } catch {
                    this.desserts = [
                        {
                            Fcontrato: "2000-01-01",
                            Fprecio: "2020-01-01",
                            Nprecio: "0",
                            Tp1: "0.2",
                            Pp1: "50",
                            Tp2: "0.2",
                            Pp2: "50",
                            Tp3: "0.2",
                            Pp3: "50",
                            Tp4: "0.2",
                            Pp4: "50",
                            Tp5: "0.2",
                            Pp5: "50",
                            Tp6: "0.2",
                            Pp6: "50"
                        }
                    ];
                    this.p1 = "0.2";
                    this.p2 = "0.2";
                    this.p3 = "0.2";
                    this.p4 = "0.2";
                    this.p5 = "0.2";
                    this.p6 = "0.2";
                    this.pc1 = "50";
                    this.pc2 = "50";
                    this.pc3 = "50";
                    this.pc4 = "50";
                    this.pc5 = "50";
                    this.pc6 = "50";
                }
                try {
                    this.dessgas = pricesResponse.data["gas"];
                    this.gas = String(pricesResponse.data["gas"][0]["Tp1"]);
                    this.gasC = String(pricesResponse.data["gas"][0]["Pp1"]);
                } catch {
                    this.dessgas = [
                        {
                            Fcontrato: "2000-01-01",
                            Fprecio: "2020-01-01",
                            Nprecio: "0",
                            Tp1: "0.2",
                            Pp1: "50"
                        }
                    ];
                    this.gas = "0.2";
                    this.gasC = "50";
                }
                try {
                    this.dessagua = pricesResponse.data["agua"];
                    this.agua = String(pricesResponse.data["agua"][0]["Tp1"]);
                    this.aguaC = String(pricesResponse.data["agua"][0]["Pp1"]);
                } catch {
                    this.dessagua = [
                        {
                            Fcontrato: "2000-01-01",
                            Fprecio: "2020-01-01",
                            Nprecio: "0",
                            Tp1: "0.2",
                            Pp1: "50"
                        }
                    ];
                    this.agua = "0.2";
                    this.aguaC = "50";
                }
                try {
                    this.dessnitrogeno = pricesResponse.data["nitrogeno"];
                    this.nitrogeno = String(
                        pricesResponse.data["nitrogeno"][0]["Tp1"]
                    );
                    this.nitrogenoC = String(
                        pricesResponse.data["nitrogeno"][0]["Pp1"]
                    );
                } catch {
                    this.dessnitrogeno = [
                        {
                            Fcontrato: "2000-01-01",
                            Fprecio: "2020-01-01",
                            Nprecio: "0",
                            Tp1: "0.2",
                            Pp1: "50"
                        }
                    ];
                    this.nitrogeno = "0.2";
                    this.nitrogenoC = "50";
                }
            }
        } catch (error) {
            if (error instanceof Error) {
                this.showError(error.message);
            }
        } finally {
            this.setProgressBar(false);
        }
    }

    async insertPrices() {
        this.setProgressBar(true);
        try {
            if (this.Fenergy) {
                const data = [
                    {
                        Fcontrato: new Date(this.ElecContrato)
                            .toISOString()
                            .split("T")[0],
                        Tp1: this.p1,
                        Pp1: this.pc1,
                        Tp2: this.p2,
                        Pp2: this.pc2,
                        Tp3: this.p3,
                        Pp3: this.pc3,
                        Tp4: this.p4,
                        Pp4: this.pc4,
                        Tp5: this.p5,
                        Pp5: this.pc5,
                        Tp6: this.p6,
                        Pp6: this.pc6
                    }
                ];
                const pricesResponse = await this.$api.savePrices<rdsa>(
                    this.client.split(" - ")[0],
                    this.install.split(" - ")[0],
                    1,
                    data
                );
                if (pricesResponse.ok) {
                    this.fetchPrices();
                }
            }
            if (this.Fgas) {
                const data = [
                    {
                        Fcontrato: new Date(this.GasContrato)
                            .toISOString()
                            .split("T")[0],
                        Tp1: this.gas,
                        Pp1: this.gasC,
                        Tp2: 0,
                        Pp2: 0,
                        Tp3: 0,
                        Pp3: 0,
                        Tp4: 0,
                        Pp4: 0,
                        Tp5: 0,
                        Pp5: 0,
                        Tp6: 0,
                        Pp6: 0
                    }
                ];
                const pricesResponse = await this.$api.savePrices<rdsa>(
                    this.client.split(" - ")[0],
                    this.install.split(" - ")[0],
                    2,
                    data
                );
            }
            if (this.Fagua) {
                const data = [
                    {
                        Fcontrato: new Date(this.AguaContrato)
                            .toISOString()
                            .split("T")[0],
                        Tp1: this.agua,
                        Pp1: this.aguaC,
                        Tp2: 0,
                        Pp2: 0,
                        Tp3: 0,
                        Pp3: 0,
                        Tp4: 0,
                        Pp4: 0,
                        Tp5: 0,
                        Pp5: 0,
                        Tp6: 0,
                        Pp6: 0
                    }
                ];
                const pricesResponse = await this.$api.savePrices<rdsa>(
                    this.client.split(" - ")[0],
                    this.install.split(" - ")[0],
                    3,
                    data
                );
            }
            if (this.Fnitrogeno) {
                const data = [
                    {
                        Fcontrato: new Date(this.NtrgContrato)
                            .toISOString()
                            .split("T")[0],
                        Tp1: this.nitrogeno,
                        Pp1: this.nitrogenoC,
                        Tp2: 0,
                        Pp2: 0,
                        Tp3: 0,
                        Pp3: 0,
                        Tp4: 0,
                        Pp4: 0,
                        Tp5: 0,
                        Pp5: 0,
                        Tp6: 0,
                        Pp6: 0
                    }
                ];
                const pricesResponse = await this.$api.savePrices<rdsa>(
                    this.client.split(" - ")[0],
                    this.install.split(" - ")[0],
                    4,
                    data
                );
                if (pricesResponse.ok) {
                    this.fetchPrices();
                }
            }
        } catch (error) {
            if (error instanceof Error) {
                this.showError(error.message);
            }
        } finally {
            this.setProgressBar(false);
        }
    }

    async deletePrice() {
        this.setProgressBar(true);
        try {
            const deleteResponse = await this.$api.deletePrice<rdsa>(
                this.Nprecio,
                this.install.split(" - ")[0]
            );
            if (deleteResponse.ok) {
                this.showError("Precio Eliminado");
                this.Nprecio = 0;
            }
        } catch (error) {
            if (error instanceof Error) {
                this.showError(error.message);
            }
        } finally {
            this.setProgressBar(false);
        }
    }

    async upgradePrice() {
        this.setProgressBar(true);
        try {
            const upgradeResponse = await this.$api.updatePrice<rdsa>(
                this.Nprecio,
                this.editedItem,
                this.install.split(" - ")[0]
            );
            if (upgradeResponse.ok) {
                this.showError("Precio Editado");
                this.Nprecio = 0;
            }
        } catch (error) {
            if (error instanceof Error) {
                this.showError(error.message);
            }
        } finally {
            this.setProgressBar(false);
        }
    }
}
